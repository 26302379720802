import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import HomeProject from "../components/homeproject";
import Seo from "../components/seo";

// markup
const ProjectPage = () => {
  const data = useStaticQuery(graphql`
    query ProjectQuery {
      allSanityProject {
        nodes {
          id
          title
          location
          description
          featurePhoto {
            asset {
              gatsbyImageData(width: 1300, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title="Projects"
        description="SCR Landscapes has worked with the best architects, builders and landscape designers in Victoria to create beautiful, bespoke outdoor spaces. Check out some of our work."
      />
      <div className="md:grid md:grid-cols-2 md:grid-flow-row-dense">
        {data.allSanityProject.nodes.map((project, i) => {
          return (
            <HomeProject
              project={project}
              reverse={i % 2 === 0}
              key={project.id}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default ProjectPage;
